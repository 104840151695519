import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ArrowUpIcon, ArrowDownIcon, MinusIcon } from 'lucide-react';

const data = [
  { date: '4/25/24', discord: 52, discordGrowth: 0, xFollowers: 263, xGrowth: 0, totalApps: 55, appsGrowth: 0, websiteVisits: 1155, visitsGrowth: 0, msgsSent: 1962, msgsGrowth: 0, slopeApps: 29, slopeGrowth: 0, lfgs: 0, lfgsGrowth: 0 },
  { date: '5/1/24', discord: 55, discordGrowth: 0, xFollowers: 328, xGrowth: 1.863354037, totalApps: 63, appsGrowth: 1.612903226, websiteVisits: 1475, visitsGrowth: 3.436185133, msgsSent: 2796, msgsGrowth: 10.38294512, slopeApps: 39, slopeGrowth: 8.333333333, lfgs: 10, lfgsGrowth: 25 },
  { date: '5/8/24', discord: 65, discordGrowth: 0, xFollowers: 367, xGrowth: 1.662049861, totalApps: 91, appsGrowth: 2.247191011, websiteVisits: 2330, visitsGrowth: 2.014010508, msgsSent: 3779, msgsGrowth: 6.211354694, slopeApps: 56, slopeGrowth: 3.703703704, lfgs: 61, lfgsGrowth: 3.389830508 },
  { date: '5/15/24', discord: 66, discordGrowth: 0, xFollowers: 449, xGrowth: 2.277904328, totalApps: 103, appsGrowth: 0, websiteVisits: 2812, visitsGrowth: 1.663051338, msgsSent: 4323, msgsGrowth: 1.004672897, slopeApps: 69, slopeGrowth: 2.985074627, lfgs: 68, lfgsGrowth: 1.492537313 },
  { date: '5/22/24', discord: 75, discordGrowth: 2.739726027, xFollowers: 534, xGrowth: 5.742574257, totalApps: 112, appsGrowth: 2.752293578, websiteVisits: 3277, visitsGrowth: 2.920854271, msgsSent: 4932, msgsGrowth: 1.606922126, slopeApps: 83, slopeGrowth: 1.219512195, lfgs: 73, lfgsGrowth: 0 },
  { date: '5/29/24', discord: 82, discordGrowth: 0, xFollowers: 603, xGrowth: 1.174496644, totalApps: 125, appsGrowth: 1.62601626, websiteVisits: 3658, visitsGrowth: 1.809073198, msgsSent: 5381, msgsGrowth: 0.95684803, slopeApps: 92, slopeGrowth: 2.222222222, lfgs: 82, lfgsGrowth: 1.234567901 },
  { date: '6/5/24', discord: 87, discordGrowth: 0, xFollowers: 645, xGrowth: 0.624024961, totalApps: 134, appsGrowth: 0, websiteVisits: 4206, visitsGrowth: 4.966308959, msgsSent: 5729, msgsGrowth: 1.00493653, slopeApps: 97, slopeGrowth: 1.041666667, lfgs: 94, lfgsGrowth: 6.818181818 },
  { date: '6/12/24', discord: 97, discordGrowth: 3.191489362, xFollowers: 730, xGrowth: 0.6896551724, totalApps: 145, appsGrowth: 0.6944444444, websiteVisits: 4739, visitsGrowth: 0.9586706434, msgsSent: 6446, msgsGrowth: 1.082013486, slopeApps: 104, slopeGrowth: 0, lfgs: 103, lfgsGrowth: 0 },
  { date: '6/19/24', discord: 103, discordGrowth: 0.9803921569, xFollowers: 787, xGrowth: 0.2547770701, totalApps: 158, appsGrowth: 0.6369426752, websiteVisits: 5171, visitsGrowth: 1.173938564, msgsSent: 6753, msgsGrowth: 0.7309069212, slopeApps: 113, slopeGrowth: 2.727272727, lfgs: 109, lfgsGrowth: 0 },
  { date: '6/26/24', discord: 106, discordGrowth: 0.9523809524, xFollowers: 820, xGrowth: 0.9852216749, totalApps: 167, appsGrowth: 1.212121212, websiteVisits: 5517, visitsGrowth: 0.9884678748, msgsSent: 7128, msgsGrowth: 1.178140525, slopeApps: 118, slopeGrowth: 0, lfgs: 112, lfgsGrowth: 1.818181818 },
  { date: '7/3/24', discord: 120, discordGrowth: 3.448275862, xFollowers: 870, xGrowth: 0.5780346821, totalApps: 175, appsGrowth: 0, websiteVisits: 5920, visitsGrowth: 1.092896175, msgsSent: 7360, msgsGrowth: 0.4641004641, slopeApps: 119, slopeGrowth: -0.8333333333, lfgs: 118, lfgsGrowth: 0 },
  { date: '7/10/24', discord: 129, discordGrowth: 0, xFollowers: 929, xGrowth: 0.2157497303, totalApps: 189, appsGrowth: 0, websiteVisits: 6552, visitsGrowth: 1.612903226, msgsSent: 7706, msgsGrowth: 0.94314907, slopeApps: 133, slopeGrowth: 0.7575757576, lfgs: 125, lfgsGrowth: 0.8064516129 },
  { date: '7/16/24', discord: 135, discordGrowth: 0, xFollowers: 964, xGrowth: 0.6263048017, totalApps: 197, appsGrowth: 2.604166667, websiteVisits: 6920, visitsGrowth: 0.7864841247, msgsSent: 7948, msgsGrowth: 0.5058168943, slopeApps: 137, slopeGrowth: 0.7352941176, lfgs: 128, lfgsGrowth: 0 },
  { date: '7/18/24', discord: 135, discordGrowth: 0, xFollowers: 980, xGrowth: 0.9268795057, totalApps: 198, appsGrowth: 0, websiteVisits: 7302, visitsGrowth: 2.888544455, msgsSent: 8057, msgsGrowth: 0.5993257585, slopeApps: 137, slopeGrowth: 0, lfgs: 130, lfgsGrowth: 1.5625 },
  { date: '7/31/24', discord: 138, discordGrowth: -1.428571429, xFollowers: 1030, xGrowth: 0.1945525292, totalApps: 209, appsGrowth: 0, websiteVisits: 9333, visitsGrowth: 1.777535442, msgsSent: 8933, msgsGrowth: 2.231631952, slopeApps: 142, slopeGrowth: 0, lfgs: 141, lfgsGrowth: 0 },
];

const metrics = [
  { name: 'Discord Members', dataKey: 'discord', growthKey: 'discordGrowth', color: '#7289DA' },
  { name: 'X Followers', dataKey: 'xFollowers', growthKey: 'xGrowth', color: '#1DA1F2' },
  { name: 'Total Apps', dataKey: 'totalApps', growthKey: 'appsGrowth', color: '#FF4500' },
  { name: 'Website Visits', dataKey: 'websiteVisits', growthKey: 'visitsGrowth', color: '#4CAF50' },
  { name: 'Messages Sent', dataKey: 'msgsSent', growthKey: 'msgsGrowth', color: '#FFC107' },
  { name: 'Slope Apps', dataKey: 'slopeApps', growthKey: 'slopeGrowth', color: '#9C27B0' },
  { name: 'LFGs', dataKey: 'lfgs', growthKey: 'lfgsGrowth', color: '#E91E63' },
];

const StatCard = ({ title, value, growth, color }) => (
  <div className="bg-white p-4 rounded-lg shadow">
    <h3 className="text-lg font-semibold mb-2">{title}</h3>
    <div className="text-3xl font-bold" style={{ color }}>{value}</div>
    <div className="flex items-center mt-2">
      {growth > 0 ? (
        <ArrowUpIcon className="text-green-500" size={16} />
      ) : growth < 0 ? (
        <ArrowDownIcon className="text-red-500" size={16} />
      ) : (
        <MinusIcon className="text-gray-500" size={16} />
      )}
      <span className={`ml-1 ${
        growth > 0 ? 'text-green-500' : 
        growth < 0 ? 'text-red-500' : 
        'text-gray-500'
      }`}>
        {Math.abs(growth)}%
      </span>
    </div>
  </div>
);

const WeeklyGrowthTable = ({ data }) => {
  const getWeekNumber = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  };

  const weeklyData = data.reduce((acc, item) => {
    const date = new Date(item.date);
    const weekNumber = getWeekNumber(date);
    if (!acc[weekNumber]) {
      acc[weekNumber] = { ...item, weekStart: new Date(date.setDate(date.getDate() - date.getDay() + 1)) };
    }
    return acc;
  }, {});

  const sortedWeeks = Object.values(weeklyData).sort((a, b) => new Date(b.weekStart) - new Date(a.weekStart));

  const calculateGrowth = (current, previous, key) => {
    if (!previous) return '-';
    const growth = ((current[key] - previous[key]) / previous[key]) * 100;
    return Math.ceil(growth) + '%';
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow overflow-x-auto">
      <h2 className="text-2xl font-semibold mb-4">Weekly Growth (All Available History)</h2>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="px-4 py-2">Week Starting</th>
            {metrics.map(metric => (
              <th key={metric.dataKey} className="px-4 py-2">{metric.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedWeeks.map((week, index) => (
            <tr key={week.weekStart.toISOString()}>
              <td className="px-4 py-2">{week.weekStart.toLocaleDateString()}</td>
              {metrics.map(metric => (
                <td key={metric.dataKey} className="px-4 py-2">
                  {week[metric.dataKey]} ({calculateGrowth(week, sortedWeeks[index + 1], metric.dataKey)})
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const GrowthDashboard = () => {
  const latestData = data[data.length - 1];
  const lastUpdatedDate = new Date(latestData.date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="p-8 bg-gray-100">
      <h1 className="text-3xl font-bold text-center mb-2">Merge Growth Dash</h1>
      <p className="text-center text-gray-600 mb-8">
        Cards below represent DoD growth, last updated on: {lastUpdatedDate}
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {metrics.map((metric) => (
          <StatCard
            key={metric.dataKey}
            title={metric.name}
            value={latestData[metric.dataKey]}
            growth={latestData[metric.growthKey]}
            color={metric.color}
          />
        ))}
      </div>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        {metrics.map((metric) => (
          <div key={metric.dataKey} className="bg-white p-4 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-4">{metric.name} Growth</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Line yAxisId="left" type="monotone" dataKey={metric.dataKey} name={metric.name} stroke={metric.color} activeDot={{ r: 8 }} />
                <Line yAxisId="right" type="monotone" dataKey={metric.growthKey} name="Daily Growth %" stroke={`${metric.color}88`} strokeDasharray="5 5" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>
      
      <div className="bg-white p-4 rounded-lg shadow mb-8">
        <h2 className="text-2xl font-semibold mb-4">Overall Growth Trends</h2>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {metrics.map((metric) => (
              <Line 
                key={metric.dataKey}
                type="monotone" 
                dataKey={metric.dataKey} 
                name={metric.name} 
                stroke={metric.color} 
                activeDot={{ r: 6 }} 
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <WeeklyGrowthTable data={data} />
    </div>
  );
};

export default GrowthDashboard;
